import { Autocomplete, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { Modal, Typography } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@mui/material';
import { Box } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Stack } from '@mui/material';
import APIManager from '../../../../lib/apiManager';
import { sistema } from '../../../../model/Sistema';
import moment, { isDate } from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Check, Delete } from '@mui/icons-material';
import UsuarioCard from '../../../Cliente/components/UsuarioCard';

const EditandoModal = ({ open, setOpen, selectedItem, columns, aprobarPedido, rechazarPedido }) => {
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState('');
  const [contrato, setContrato] = useState(null);
  const [paquetesDeCreditos, setPaquetesDeCreditos] = useState([]);
  const [paqueteDeCreditos, setPaqueteDeCreditos] = useState('');
  const [sede, setSede] = useState(null);

  const [cantidadDeCreditos, setCantidadDeCreditos] = useState(0);
  const [cantidadVariable, setCantidadVariable] = useState(false);
  const [clienteNombre, setClienteNombre] = useState('');
  const [solicitadoPorNombre, setSolicitadoPorNombre] = useState('');
  const [solicitadoEn, setSolicitadoEn] = useState('');
  const [valorDeCompra, setValorDeCompra] = useState('');
  const [monedaDeCompra, setMonedaDeCompra] = useState('');
  const [estadoDelContrato, setEstadoDelContrato] = useState('Desconocido!!');
  const [contratoOk, setContratoOk] = useState(false);

  const [nuevoItem, setNuevoItem] = useState(null);
  const [confirmable, setConfirmable] = useState(false);
  const [modificable, setModificable] = useState(false);

  const clientesRef = useRef(null);

  const usuario = sistema.usuario;
  const sedes = sistema.espacios;

  const navigate = useNavigate();

  const cargarPaquetesDeCreditos = () => {
    APIManager.getList('paquetesDeCreditos', {noDisponibles: true}, (response) => {
      response.data.listado.forEach(item => {
        item.descripcion = item.nombre + ' (' + item.cantidadDeCreditos + ' créditos)';
      })
      setPaquetesDeCreditos(response.data.listado);
    });
  }

  const cargarClientes = (clienteNombre = '') => {
    APIManager.getList('clientes', { orderBy: 'client_name', active: true, nombre: clienteNombre }, (response) => {
      response.data.listado.forEach(item => {
        if (item.tipo === 'EMPRESA') {
          item.nombre = item.razonSocial + (!!item.referente ? ' [EMPRESA: ' + item.referente?.nombre + ']' : '');
        } else {
          item.nombre = (item.referente?.descripcion ?? item.referente?.nombre) ?? item.razonSocial;
        }
      })
      setClientes(response.data.listado);
    });
  }

  const cargarContratoDe = (cliente) => {
    APIManager.sendRequest('post', 'contratos', 'tieneContrato', { cliente: cliente }, (response) => {
      if (response.success) {
        if (!!response.data.contrato) {
          setContrato(response.data.contrato);
          checkEstadoDelContratoPara(response.data.contrato);
        }
      }
    });
  }

  const actualizarCliente = (clienteData) => {
    if (!!clienteData) {
      if (!!clientes && clientes.length > 0) {
        setCliente(clientes.find(item => item.id === clienteData.id));
      } else {
        setCliente(clienteData);
      }
    }
  }

  const cargarPedido = () => {
    APIManager.sendRequest('post', 'creditos', 'getPedido', { pedido: selectedItem }, (response) => {
      if (response.success) {
        if (!!response.data?.pedido) {
          const pedido = response.data.pedido;
          if (!!pedido?.orden?.items) {
            pedido.orden.items = Object.values(pedido.orden.items);
          }
          sistema.actual.pedido = pedido;
          checkEstadoDelContratoPara(pedido.contrato);
          // if (!!pedido.orden?.cuenta?.owner) {
          //   // setCliente(pedido.orden?.cuenta?.owner); // El dueño de la cuenta que va a recibir los creditos
          //   actualizarCliente(pedido.orden?.cuenta?.owner);// El dueño de la cuenta que va a recibir los creditos
          //   // setClienteNombre(pedido?.orden?.cuenta?.owner?.nombre);
          // } else {
          //   actualizarCliente(pedido);
          // }
          if (!!pedido.orden?.items?.length > 0) {
            const item = pedido.orden.items[0];
            pedido.paqueteDeCreditos = item.producto;
            // Suponemos que el pedido incluye un solo item con el paquete de creditos solicitado
            // setPaqueteDeCreditos(!!(paquetesDeCreditos?.length > 0) ?  : item.producto); // El producto solicitado
            // setCantidadDeCreditos(item.cantidad * item.producto.cantidadDeCreditos);
            // setValorDeCompra(item.cantidad * item.valor);
            setMonedaDeCompra(item.moneda);
          }
        }
      }
    });
  }

  const checkEstadoDelContratoPara = (contrato) => {
    // Devuelve un texto informativo legible sobre el contrato dado
    // console.log('checkEstadoDelContratoPara', contrato);
    let estado = '';
    let estadoOk = true;
    if (!contrato) {
      setEstadoDelContrato('Sin contrato');
      setContratoOk(false);
      return false;
    }
    if (new Date(contrato.vigenciaDesde?.date) < new Date()) {
      if (!contrato.vigenciaHasta || (new Date(contrato.vigenciaHasta?.date) > new Date())) {
        // El contrato esta vigente
        estado += 'Vigente ';
      } else {
        estado += 'Vencido ';
        estadoOk = false;
      }
    } else {
      estado += 'NO vigente ';
      estadoOk = false;
    }
    if (!!contrato.fechaDeAprobacion) {
      // El contrato esta aprobado
      estado += 'y Aprobado';
    } else {
      estado += 'y NO Aprobado';
      estadoOk = false;
    }
    setContratoOk(estadoOk);
    setEstadoDelContrato(estado);
  }

  const verificarConfirmable = () => {
    // setNuevoItem(Object.assign({}, selectedItem, { cliente: cliente, paquete: paqueteDeCreditos, creditos: cantidadDeCreditos}));
    setConfirmable(!!cliente && !!paqueteDeCreditos && !!cantidadDeCreditos && !!contratoOk);
  }

  const handleContinuar = () => {
    // if (!!selectedItem.id) {
      aprobarPedido(selectedItem);
    // } else {
    //   const nuevoItem = Object.assign({}, selectedItem);
    //   nuevoItem.cantidad = cantidadVariable ? cantidadDeCreditos / paqueteDeCreditos.cantidadDeCreditos : 1;
    //   nuevoItem.paqueteDeCreditos = paqueteDeCreditos;
    //   nuevoItem.
    //   aprobarPedido(nuevoItem);
    // }
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    rechazarPedido(selectedItem);
    handleClose();
  }

  const handleClienteNombreKeyUp = (event) => {
    if (event.key === "Enter") {
      cargarClientes(event.target.value);
      // console.log('clientesRef', clientesRef);
      window.select = clientesRef.current;
      clientesRef.current?.click();
    }
  }

  const handleClienteNombre = (event) => {
    setClienteNombre(event.target.value);
    if (!!cliente) {
      setCliente(null);
    }
  }

  const handleCliente = (event, item) => {
    // setCliente(event.target.value);
    setCliente(item);
    if (!!item) {
      cargarContratoDe(item);
      if (!!item?.nombre) {
        setClienteNombre(item.nombre);
      }
    } else {
      checkEstadoDelContratoPara(null);
    }
  }

  const handlePaqueteDeCreditos = (event) => {
    const paquete = event.target.value;
    setPaqueteDeCreditos(paquete);
    setCantidadDeCreditos(paquete.cantidadDeCreditos);
  }

  const handleCantidadDeCreditos = (event) => {
    setCantidadDeCreditos(event.target.value);
  }

  const handleValorDeCompra = (event) => {
    setValorDeCompra(event.target.value);
  }

  const handleSede = (event) => {
    setSede(event.target.value);
  }

  useEffect(() => {
    if (!!sistema?.actual?.pedido) {
      sistema.actual.pedido.cliente = cliente;
      sistema.actual.pedido.contrato = contrato;
      sistema.actual.pedido.sede = sede;
      sistema.actual.pedido.paqueteDeCreditos = paqueteDeCreditos;
      sistema.actual.pedido.cantidadDeCreditos = cantidadDeCreditos;
      sistema.actual.pedido.cantidad = cantidadVariable ? cantidadDeCreditos / (paqueteDeCreditos?.cantidadDeCreditos ?? 1) : 1;
      sistema.actual.pedido.valor = valorDeCompra;
    }
  }, [cliente, contrato, valorDeCompra, sede, paqueteDeCreditos, cantidadDeCreditos]);

  useEffect(() => {
    // if (!paqueteDeCreditos) {
      if (!!selectedItem && (paquetesDeCreditos.length > 0)) {
        // console.log('setPaqueteDeCreditos', selectedItem);
        let paquete = paquetesDeCreditos.find(item => item.id === selectedItem.paqueteDeCreditos?.id);
        if (!paquete) {
          paquete = paquetesDeCreditos.find(item => parseFloat(item.cantidadDeCreditos) == parseFloat(selectedItem.amount));
        }
        setPaqueteDeCreditos(paquete);
      }
    // }
  }, [selectedItem, paquetesDeCreditos]);

  useEffect(() => {
    // console.log('setCantidadVariable');
    setCantidadVariable(paqueteDeCreditos?.cantidadDeCreditos === 1);
  },[paqueteDeCreditos])

  useEffect(() => {
    // console.log('setCliente', selectedItem);
    if (!!selectedItem && !!clientes && (clientes.length > 0) && !cliente) {
      let elCliente = null;
      if (!!selectedItem?.orden?.cuenta?.owner?.id) {
        elCliente = clientes.find(item => item.id === sistema?.actual?.pedido?.orden.cuenta.owner.id);
      } else {
        elCliente = clientes.find(item =>
          (item.type_id == selectedItem.receiver_type_id)
          && (item.referente?.id == selectedItem.receiver_id)
        );
      }
      if (!!elCliente) {
        setCliente(elCliente);
      }
    }
  }, [selectedItem, clientes]);

  useEffect(() => {
    if (!!selectedItem) {
      cargarClientes();
      cargarPaquetesDeCreditos();
      if (!!selectedItem.id) {
        cargarPedido(selectedItem);
        setModificable(false);
        // setCliente(selectedItem.cliente);
        setClienteNombre(selectedItem.receiver_name);
        setSolicitadoPorNombre(selectedItem.agent_name);
        setSolicitadoEn(moment(new Date(selectedItem.time_asked)).format('YYYY-MM-DD'));
        setCantidadDeCreditos(selectedItem.amount);
        // setTipoDeCliente(selectedItem?.tipoDeCliente ?? '');
        setMonedaDeCompra('ARS');
      } else {
        setModificable(true);
        setCantidadVariable(false);
        setClienteNombre('');
        setCliente(null);
        setPaqueteDeCreditos(null);
        setSolicitadoPorNombre(usuario.nombreCompleto);
        setSolicitadoEn(moment(new Date()).format('YYYY-MM-DD'));
        setCantidadDeCreditos(0);
        setValorDeCompra(0);
        setEstadoDelContrato('Desconocido!');
        setContratoOk(false);
        setMonedaDeCompra('ARS');
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!!paqueteDeCreditos) {
      // console.log('setValorDeCompra',paqueteDeCreditos);
      setValorDeCompra(parseFloat(paqueteDeCreditos.valor) * (paqueteDeCreditos.cantidadDeCreditos === 1 ? cantidadDeCreditos : 1));
      // setMonedaDeCompra(paqueteDeCreditos.moneda);
    }
  }, [cantidadDeCreditos, paqueteDeCreditos]);

  useEffect(() => verificarConfirmable(), [cliente, paqueteDeCreditos, sede, cantidadDeCreditos, contratoOk]);

  // useEffect(() => { sistema.actual = nuevoItem ?? {} }, [nuevoItem]);

  /*
  useEffect(() => {
      APIManager.sendRequest('post','ui','getPaquetesDeCreditos', {}, (response) => {
        if (response.success) {
          setPaquetesDeCreditos(response.data);
        }
      })
  }, []);
 */

  return (
    <Dialog
    open={open}
    aria-labelledby="nuevoContrato-dialog-title"
    aria-describedby="nuevoContrato-dialog-description"
  >
      <DialogTitle id="alert-dialog-title">
        Compra de créditos
      </DialogTitle>
      <DialogContent>
{/*         <DialogContentText id="nuevoContrato-dialog-description">
           Datos del usuario
        </DialogContentText>
 */}
        <Grid container spacing={2} >
          {/* <Grid item sm={6}>
            <FormControl variant="standard">
              <InputLabel id="tipoDeCliente-label" shrink={true} >Tipo de Cliente</InputLabel>
              <Select
                // labelId="tipoDeCliente-label"
                id="tipoDeCliente-select"
                value={tipoDeCliente}
                onChange={handleTipoDeCliente}
                disabled={!editando}
                label="Tipo de Cliente"
              >
                {tiposDeCliente.map(
                  (tipo => <MenuItem value={tipo.tipo}>{tipo.descripcion}</MenuItem>)
                )}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item sm={12}>
            <Autocomplete
              id="cliente-input"
              options={clientes}
              value={cliente}
              onChange={handleCliente }
              getOptionLabel={item => item?.nombre ?? clienteNombre}
              sx={{ width: "100%" }}
              disabled={!modificable}
              // isOptionEqualToValue={(item, value) => (item === value) || (item?.id == value)}
              renderInput={(params) =>
                <TextField variant="standard" {...params} label="Cliente" />
                // <FormControl variant="standard" sx={{ width: "100%" }}>
                  // <InputLabel id="cliente-label">Cliente</InputLabel>
                  // <Input {...params} label="Cliente" />
                // </FormControl>
              }
              title={cliente?.nombre}
            />
          </Grid>
{/*
          <Grid item sm={6} position="relative">
            <FormControl variant="standard" sx={{ width: "calc(100% - 32px)", zIndex: 10 }}>
              <InputLabel id="cliente-label">Cliente</InputLabel>
              <Input
                // labelId="cliente-label"
                id="cliente-input"
                value={clienteNombre}
                onChange={handleClienteNombre}
                onKeyUp={handleClienteNombreKeyUp}
                disabled={!!selectedItem?.id}
                label="Cliente"
                error={!cliente}
                title={clienteNombre}
                />
            </FormControl>
            {!selectedItem?.id && <FormControl variant="standard" sx={{ width: "calc(100% - 16px)", position: "absolute", top: "32px", left: "16px" }}>
              {((!!clientes && (clientes.length > 0)) ?
                <>
                  {/* <InputLabel id="cliente-label">Cliente</InputLabel> */}
{/*
                  <Select
                    labelId="cliente-label"
                    id="cliente-select"
                    value={cliente}
                    onChange={handleCliente}
                    label="Cliente"
                    ref={clientesRef}
                  >
                    {!!clientes && clientes.map(
                      (clienteItem => <MenuItem value={clienteItem}>{clienteItem.nombre}</MenuItem>)
                    )}
                  </Select>
                  {/* {!!contratoExistente && <FormHelperText variant='primary'>El cliente ya tiene un contrato (se renovara el existente)</FormHelperText>} */}
{/*
                </>
                : <Stack direction="row" alignItems="center">
                  <Typography>Cargando clientes...</Typography>
                  <CircularProgress labelId="cliente-label" />
                </Stack>)
              }
            </FormControl>
          }
          </Grid> */}
          <Grid item sm={6}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              {((!!paquetesDeCreditos && (paquetesDeCreditos.length > 0)) ?
                <>
                  <InputLabel id="cliente-label">Paquete de Créditos</InputLabel>
                  <Select
                    labelId="cliente-label"
                    id="cliente-select"
                    value={paqueteDeCreditos}
                    onChange={handlePaqueteDeCreditos}
                    label="Paquete de créditos"
                    disabled={!modificable}
                  >
                    {!!paquetesDeCreditos && paquetesDeCreditos.map(
                      (item => <MenuItem value={item}>{item.descripcion}</MenuItem>)
                    )}
                  </Select>
                  {/* {!!contratoExistente && <FormHelperText variant='primary'>El cliente ya tiene un contrato (se renovara el existente)</FormHelperText>} */}
                </>
                : <Stack direction="row" alignItems="center">
                  <Typography>Cargando paquetes...</Typography>
                  <CircularProgress labelId="cliente-label" />
                </Stack>)
              }
            </FormControl>
          </Grid>
          <Grid item sm={6} >
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="solicitadoEn-label">Créditos solicitados</InputLabel>
              <Input
                // labelId="solicitadoPor-label"
                id="solicitadoPor-input"
                type="numeric"
                value={cantidadDeCreditos}
                onChange={handleCantidadDeCreditos}
                disabled={!(modificable && cantidadVariable)}
                label="Créditos solicitados"
              />
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="solicitadoPor-label">Solicitado por</InputLabel>
              <Input
                // labelId="solicitadoPor-label"
                id="solicitadoPor-input"
                value={solicitadoPorNombre}
                // onChange={handleChangeUsername}
                disabled={true}
                label="Solicitado por"
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} >
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="solicitadoEn-label">Fecha de solicitud</InputLabel>
              <Input
                // labelId="solicitadoPor-label"
                id="solicitadoPor-input"
                type='date'
                value={solicitadoEn}
                // onChange={handleChangeUsername}
                disabled={true}
                label="Fecha de solicitud"
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} >
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="valor-label">Valor</InputLabel>
              <Input
                // labelId="solicitadoPor-label"
                id="valor-input"
                type="numeric"
                value={valorDeCompra}
                onChange={handleValorDeCompra}
                disabled={!modificable}
                label="Valor"
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} >
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="valor-label">Moneda</InputLabel>
              <Input
                // labelId="solicitadoPor-label"
                id="valor-input"
                type="numeric"
                value={monedaDeCompra}
                // onChange={handleChangeUsername}
                disabled={true}
                label="Moneda"
              />
            </FormControl>
          </Grid>
          <Grid item sm={12} >
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="estadoDelContrato-label">Estado del contrato</InputLabel>
              <Input
                // labelId="solicitadoPor-label"
                id="estadoDelContrato-input"
                value={estadoDelContrato}
                // onChange={handleChangeUsername}
                disabled={true}
                label="Estado del contrato"
                error={!contratoOk}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={handleContinuar} disabled={!confirmable}>
          Aprobar
        </Button>
        {selectedItem?.id && <Button variant='contained' onClick={handleCancel}>
          Rechazar
        </Button>}
      </DialogActions>
    </Dialog>
  );
}

export default EditandoModal;