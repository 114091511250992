import React from "react";

export default ({ id, activo, empresa, descripcion, descuento, nombre, places, web, is_maquinero, logoURL,  ...props }) => {

  return (
    <div className="beneficio-card" >
      <div className="beneficio-card__avatar">
        <i className="beneficio-card__avatar__notification-icon icon--circle hidden"></i>
        {logoURL
          ? <div className="beneficio-card__avatar__image" src={logoURL} style={{backgroundImage: `url(${logoURL})`}} alt="" />
          : <div className="beneficio-card__avatar__image">
              <span className="beneficio-card__avatar__text">
                {nombre ? nombre[0] : ""}
              </span>
            </div>
        }
      </div>
      <h1 className="beneficio-card__nombre">
        {empresa}
      </h1>
      <p className="beneficio-card__titulo">
        {nombre}
      </p>
      {descuento &&
        <p className="beneficio-card__descuento">
          {descuento}
        </p>}
    </div>
  );
};
