import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import { sistema } from "../../model/Sistema";

const Beneficios = ({setBotonera}) => {

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('company');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  const [active, setActive] = useState(sistema?.dataDeBusqueda?.active ?? true);
  const [textoDeBusqueda, setTextoDeBusqueda] = useState(sistema?.dataDeBusqueda?.textoDeBusqueda ?? '');

  const [cambioEnFiltros, setCambioEnFiltros] = useState(false);

  const navigate = useNavigate();

  const columns = [
    {
      id: 'company',
      numeric: false,
      disablePadding: true,
      label: 'Empresa',
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'Titulo',
    },
    {
      id: 'prestador_nombre',
      numeric: false,
      disablePadding: true,
      label: 'Prestador',
    },
    {
      id: 'activo',
      numeric: false,
      disablePadding: true,
      label: 'Activo',
    },
  ];

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const openItem = (event, id) => {
    navigate('/beneficios/' + id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actualizarItems = () => {
    setRows([]);
    sistema.dataDeBusqueda = {textoDeBusqueda, active};
    APIManager.getList('beneficios', {orderBy: 'company desc', conPrestador: true, nombre: textoDeBusqueda, inactivos: !active}, (response) => {
      if (response.success) {
        setRows(response.data.listado);
      }
    })
  }

  const toggleActive = (event) => {
    setActive(!active);
  }

  const handleChangeTextoDeBusqueda = (event) => {
    setTextoDeBusqueda(event.target.value);
    setCambioEnFiltros(true);
  }

  const handleChangeActive = event => {
    setActive(event.target.checked);
    setCambioEnFiltros(true);
  }

  const handleTextoDeBusquedaKeyup = (event) => {
    if (event.code === 'Enter') {
      actualizarItems();
    }
  }

  useEffect(() => {
    sistema.actual.active = active;
    setBotonera([
      { type: 'input', id: 'input-texto', inputType: 'text', title: 'Texto de búsqueda', label: 'Texto de búsqueda', value: textoDeBusqueda, onChange: handleChangeTextoDeBusqueda, onKeyUp: handleTextoDeBusquedaKeyup },
      { type: 'input', inputType: 'checkbox', title: 'Mostrar solo Beneficios activos', label: 'Solo activos',  checked: active, onChange: handleChangeActive },
      { title: 'Buscar', label: 'Buscar', variant: (cambioEnFiltros ? 'contained' : 'outlined'), onClick: () => { actualizarItems() } },
      { title: 'Nuevo', label: 'Nuevo', variant: 'outlined', onClick: () => { openItem(null, 'nuevo') } },
    ]);
  }, [active, textoDeBusqueda]);

  useEffect(() => {
    actualizarItems();
  }, [])

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Typography padding={2} fontWeight="bold" fontSize="large">Lista de Beneficios { !!active && 'activos' }</Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                  rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.cliente_id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => openItem(event, row.id ?? row.cliente_id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row.cliente_id)}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      {columns.map(column => <TableCell
                        // component="th"
                        id={labelId}
                        // scope={columnIndex === 0 ? "row" : ""}
                        padding={column.disablePadding ? 'none' : 'normal'}
                        align={column.numeric ? 'right' : 'left'}
                        >
                        {column.id === 'activo'
                          ? row.activo ? 'SI' : 'NO'
                          : row[column.id]}
                      </TableCell>)}
{/*
                      <TableCell align="left">{row.tipo}</TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.nombre}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.razonSocial}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.cuit}
                      </TableCell>
                      <TableCell align="right">{row.active ? 'SI' : 'NO'}</TableCell>
                       */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </Box>
  );
}

export default Beneficios;