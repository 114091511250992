import { Box, Checkbox, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import { sistema } from "../../model/Sistema";
import { Visibility } from "@mui/icons-material";
import moment from 'moment';
import EditandoModal from "./components/EditandoModal";

const MiembrosPendientes = ({setBotonera, espacios, espacio}) => {

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('registro');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);

  const [active, setActive] = useState(true);
  const [nombre, setNombre] = useState('');
  const [profesion, setProfesion] = useState('');
  const [tipo, setTipo] = useState('');

  const [loading, setLoading] = useState(false);
  const [cambioEnFiltros, setCambioEnFiltros] = useState(false);
  const [espacioSeleccionado, setEspacioSeleccionado] = useState(espacio);

  const [editando, setEditando] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();

  const profesionDe = (miembro) => {
    const cargoStr = miembro?.relacionLaboral?.cargo ?? '';
    const empresaStr = miembro?.relacionLaboral?.empresa?.nombre ?? (miembro?.relacionLaboral?.profesion?.nombre ?? '');

    const profesionStr = (!!cargoStr ? cargoStr + ' en ' : '') + empresaStr;

    return profesionStr;
  }

  const columns = [
    {
      id: 'descripcion',
      numeric: false,
      disablePadding: false,
      label: 'Nombre',
    },
    {
      id: 'tipo',
      // valueArray: ['relacionLaboral','tipo'],
      numeric: false,
      disablePadding: false,
      label: 'Tipo',
    },
    {
      id: 'profesion',
      // valueFunc: profesionDe,
      // valueArray: [['relacionLaboral','empresa','nombre'],['relacionLaboral','profesion','nombre']],
      numeric: false,
      disablePadding: false,
      label: 'Profesión',
    },
    {
      id: 'registro',
      numeric: false,
      disablePadding: false,
      label: 'Registro',
    },
    {
      id: 'activo',
      numeric: false,
      disablePadding: false,
      label: 'Activo',
    },
    {
      id: 'acciones',
      numeric: false,
      disablePadding: false,
      label: '',
    },
  ];

  const valueFrom = (item, key) => {
    // console.log('valueFrom', item, key);
    switch (typeof item) {
      case 'undefined':
      case 'null':
      case 'string':
      case 'numeric':
      case 'boolean':
        return item;
    }
    if (typeof key === 'string') {
      if (!!item) {
        return item[key];
      } else {
        return item;
      }
    }
    if (Array.isArray(key)) {
      return arrayValuesFrom(item, key);
    }
  }

  const arrayValuesFrom = (item, keys) => {
    // console.log('arrayValuesFrom', item, keys);
    let value = Object.assign({}, item);
    keys.forEach(key => {
      let newValue = valueFrom(value, key);
      if (!!newValue) {
        value = newValue;
      }
    });
    return value;
  }

  const valueForRow = (row, column) => {
    // console.log('valueForRow', row, column);
    let value = '';
    if (!!column.valueFunc) {
      value = column.valueFunc(row);
    } else
    if (!!column.valueArray) {
      value = arrayValuesFrom(row, column.valueArray);
    } else
    if (column.id === 'activo')
    {
      value = row.activo ? 'SI' : 'NO';
    } else {
      value = row[column.id];
    }
    return value;
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const openItem = (event, item) => {
    // Abre el item correspondiente
    setSelectedItem(item);
    // setEditando(true);
    console.log('openItem', item);
    // navigate('/pendientes/' + item.id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const actualizarItems = () => {
    setLoading(true);
    setRows([]);
    setPage(0);
    setCambioEnFiltros(false);
    APIManager.getList('usuarios', {nombre: nombre, profesion: profesion, espacioID: espacioSeleccionado?.id ?? null, paraV1: true, tipo: tipo, pendientes: true, activos: active}, (response) => {
      if (response.success) {
        response.data.listado.forEach(item => {
          item.profesion = profesionDe(item) ?? '';
          item.tipo = item?.relacionLaboral?.tipo ?? 'INCOMPLETO';
          item.tipoDeCliente = item.tipo === 'EMPLEADO' ? 'EMPRESA' : item.tipo;
          item.registro = moment(new Date(item.date_joined)).format('YYYY-MM-DD');
        });
        window.usuarios = response.data.listado;
        setRows(response.data.listado);
        setLoading(false);
      }
    })
  }

  const toggleActive = (event) => {
    setActive(!active);
  }

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
    setCambioEnFiltros(true);
  }

  const handleChangeProfesion = (event) => {
    setProfesion(event.target.value);
    setCambioEnFiltros(true);
  }

  const handleChangeTipo = (event) => {
    setTipo(event.target.value);
    setCambioEnFiltros(true);
  }

  const handleChangeEspacio = (event) => {
    setEspacioSeleccionado(event.target.value);
    console.log('handleChangeEspacio', event.target.value);
    setCambioEnFiltros(true);
  }

  const handleChangeActive = event => {
    setActive(event.target.checked);
    setCambioEnFiltros(true);
  }

  const handleEnterKeyup = (event) => {
    if (event.code === 'Enter') {
      actualizarItems();
    }
  }

  const aceptarMiembro = () => {
  }

  useEffect(() => {
    sistema.actual.active = active;
    sistema.actual.filtros = { espacioSeleccionado, tipo, nombre, profesion };
    setBotonera([
      // { type: 'button', title: 'Mostrar solo Clientes activos', label: 'Solo activos', variant: active ? 'contained' : 'outlined', onClick: toggleActive },
      { type: 'input', id: 'input-nombre', inputType: 'text', title: 'Nombre', label: 'Nombre', value: nombre, onChange: handleChangeNombre, onKeyUp: handleEnterKeyup },
      { type: 'input', id: 'input-profesion', inputType: 'text', title: 'Profesión', label: 'Profesión', value: profesion, onChange: handleChangeProfesion, onKeyUp: handleEnterKeyup },
      {
        type: 'select', id: 'select-tipo', control:
          <FormControl sx={{minWidth: 150}}>
            <InputLabel id="tipo-label">Tipo</InputLabel>
            <Select
              labelId="tipo-label"
              value={tipo}
              label="Tipo"
              onChange={handleChangeTipo}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="FREELANCER">Freelancer</MenuItem>
              <MenuItem value="EMPLEADO">Empleado</MenuItem>
            </Select>
          </FormControl>
        , title: 'Tipo', label: 'Tipo', value: tipo, onChange: handleChangeTipo
      },
      {
        type: 'select', id: 'select-espacio', control:
          <FormControl sx={{minWidth: 150}}>
            <InputLabel id="espacio-label">Espacio</InputLabel>
            <Select
              labelId="espacio-label"
              value={espacioSeleccionado}
              label="Espacio"
              onChange={handleChangeEspacio}
            >
              <MenuItem value="">Todos</MenuItem>
              {espacios.map(item => <MenuItem value={item}>{item.nombre}</MenuItem>)}
            </Select>
          </FormControl>
        , title: 'Espacio', label: 'Espacio', value: tipo, onChange: handleChangeEspacio
      },
      { type: 'input', inputType: 'checkbox', title: 'Mostrar solo Clientes activos', label: 'Solo activos', checked: active, onChange: handleChangeActive },
      { type: 'button', title: 'Buscar', label: 'Buscar', variant: (cambioEnFiltros ? 'contained' : 'outlined'), onClick: () => { actualizarItems() } },
      // { title: 'Nuevo', label: 'Nuevo', variant: 'outlined', onClick: () => { openItem(null, 'nuevo') } },
    ]);
  }, [active, nombre, profesion, tipo, espacioSeleccionado, cambioEnFiltros]);

  useEffect(() => {
    actualizarItems();
  }, [])

  return (
    <Box>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EditandoModal open={editando} setOpen={setEditando} columns={columns} selectedItem={selectedItem} aceptarMiembro={aceptarMiembro} />
        <Typography padding={2} fontWeight="bold" fontSize="large">Lista de Miembros { !!active && 'activos' }</Typography>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < rows.length}
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell> */}
                {columns.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {!!rows && rows.length > 0 ?
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => openItem(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                        {columns.map(column => <TableCell
                          // component="th"
                          id={labelId}
                          // scope={columnIndex === 0 ? "row" : ""}
                          padding={column.disablePadding ? 'none' : 'normal'}
                          align={column.numeric ? 'right' : 'left'}
                        >
                          {column.id !== 'acciones'
                            ? valueForRow(row, column)
                            : <Stack direction="row">
                              <IconButton title="Ver Detalles" onClick={(event) => openItem(event, row)}><Visibility /></IconButton>
                            </Stack>
                            }
                        </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              : (loading
                ? <TableBody><TableRow><TableCell colSpan={columns.length} padding="normal" align="center"><CircularProgress /></TableCell></TableRow></TableBody>
                : <TableBody><TableRow><TableCell colSpan={columns.length} align="center"><Typography>{'No hay datos para mostrar'}</Typography></TableCell></TableRow></TableBody>)
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </Box>
  );
}

export default MiembrosPendientes;